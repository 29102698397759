import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useHostingFixownership } from '@newfold/huapi-js';

import { useAlert } from '~/components/Alerts/alertsStore';
import useAccount from '~/hooks/useAccount';
import useResponsive from '~/hooks/useResponsive';
import MultiStepCardHeader from '~/scenes/AddSetupSiteFlows/components/MultiStepCard/CardHeader';

export default function FixFileOwnership() {
  const { t } = useTranslation('ownership');
  const [isDisclaimerAccepted, setIsDisclaimerAccepted] = useState(false);
  const { id: hostingId } = useAccount();
  const generateAlert = useAlert();
  const { isMobile } = useResponsive();

  const navigate = useNavigate();
  const setAlertMessage = (variant: string, descKey: string) => {
    generateAlert({
      severity: variant,
      description: t(descKey),
      showCloseBtn: true,
    });
  };

  const { mutate: checkFileOwnership, isPending: fixingFileOwnership } =
    useHostingFixownership({
      mutation: {
        onSuccess: () => {
          setAlertMessage('success', t('successMessage'));
        },
        onError: () => {
          setAlertMessage('error', t('errorMessage'));
        },
        onSettled: () => {
          setIsDisclaimerAccepted(false);
        },
      },
    });

  const handleFixFileOwnership = () => {
    checkFileOwnership({ hostingId });
  };

  const handleDisclaimerCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsDisclaimerAccepted(event.target.checked);
  };

  const handleBackNavigation = () => navigate(-1);

  return (
    <Container>
      <Stack spacing={2}>
        <MultiStepCardHeader
          headerTitle={t('pageTitle')}
          headerVariant="h1"
          handleBack={handleBackNavigation}
        />
        <Card sx={{ p: 1 }} data-testid="fixFileOwnership-card">
          <CardHeader title={t('cardTitle')}></CardHeader>
          <CardContent>
            <Stack spacing={4}>
              <Typography>
                {t('message')}
                <Link
                  href={''}
                  target="_blank"
                  data-testid="fixOwnership-learn-more"
                >
                  {t('learnMore')}
                </Link>
              </Typography>
              <Divider />
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h3">{t('disclaimerHeader')}</Typography>
                <FormGroup sx={{ alignContent: 'flex-start' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleDisclaimerCheck}
                        checked={isDisclaimerAccepted}
                        data-testid="fixFileOwnership-disclaimerAction"
                      />
                    }
                    label={t('disclaimer')}
                  />
                </FormGroup>
              </Box>
            </Stack>
          </CardContent>
          <CardActions>
            <Box
              justifyContent={{ sm: 'flex-end' }}
              display="flex"
              sx={{ width: '100%' }}
            >
              <LoadingButton
                variant="contained"
                onClick={handleFixFileOwnership}
                loading={fixingFileOwnership}
                disabled={!isDisclaimerAccepted}
                fullWidth={isMobile()}
                data-testid="fixFileOwnership-primaryAction"
              >
                {t('primaryAction')}
              </LoadingButton>
            </Box>
          </CardActions>
        </Card>
      </Stack>
    </Container>
  );
}

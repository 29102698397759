import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';

import useSitePath from '~/hooks/useSitePath';

import noSitePictureWide from '../../../../../../Sites/assets/placeholders/site-no-picture-wide.svg';

const thumbnailBgColorOneOff = '#d8e0eb';
const aspectX = 16;
const aspectY = 9;

export const siteThumbnailSkeletonSx = {
  aspectRatio: `${aspectX}/${aspectY}`,
  width: '100%',
};

export const siteThumbnailSx = {
  ...siteThumbnailSkeletonSx,
  backgroundColor: thumbnailBgColorOneOff,
};

type SiteThumbnailProps = {
  disabled: boolean;
  id: number;
};

const SiteThumbnail = ({ disabled, id }: SiteThumbnailProps) => {
  const { t } = useTranslation('sites');
  const theme = useTheme();
  const { siteRedirect } = useSitePath();

  const handleSiteNavigation = () => {
    // go to site detail page
    siteRedirect(id);
  };

  return (
    <Box
      component="img"
      src={noSitePictureWide}
      aria-disabled={disabled}
      alt={t('siteThumbnailImageAlt')}
      onClick={handleSiteNavigation}
      sx={{
        ...siteThumbnailSx,
        border: `1px solid ${theme.palette.grey[400]}`,
        cursor: 'pointer',
      }}
    />
  );
};

export default SiteThumbnail;

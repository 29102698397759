// Remove SX props
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Stack from '@mui/material/Stack';
import { Variant } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';

type MultiStepCardHeaderPropOptions = {
  headerTitle: string | ReactElement;
  headerVariant?: Variant;
  hasBackArrow?: boolean;
  handleBack?: VoidFunction;
};

const MultiStepCardHeader = ({
  headerTitle,
  headerVariant = 'h2',
  hasBackArrow = true,
  handleBack = undefined,
}: MultiStepCardHeaderPropOptions) => {
  let navigate = useNavigate();
  return (
    <Stack direction="row" alignItems="center">
      {hasBackArrow && (
        <ArrowBackIosIcon
          sx={{ cursor: 'pointer' }}
          data-testid="card-header"
          onClick={
            typeof handleBack === 'function' ? handleBack : () => navigate(-1)
          }
          color="primary"
        />
      )}
      <Typography variant={headerVariant}>{headerTitle}</Typography>
    </Stack>
  );
};

export default MultiStepCardHeader;

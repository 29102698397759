import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

type CopyPropOptions = {
  copyValue?: string;
  disable?: boolean;
  icon?: ReactElement;
  // requiring children to be a single React element or string if provided
  children?: ReactElement | string;
};
const Copy = ({
  children = undefined,
  copyValue = undefined,
  disable = false,
  icon = undefined,
}: CopyPropOptions) => {
  const { t } = useTranslation('site', { keyPrefix: 'Copy' });
  const [copied, setCopied] = useState(false);

  const childrenIsElement = typeof children === 'object';
  const childrenText = childrenIsElement ? children?.props?.children : children;

  const handleCopy = () => {
    const findCopy = copyValue || childrenText;

    try {
      navigator.clipboard.writeText(findCopy as string);
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }

    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems="baseline"
      spacing={{ sm: 1 }}
    >
      {children}
      <Tooltip open={copied} title={t('copiedMsg')} placement="top-end">
        <Button
          sx={{ minWidth: icon ? 0 : '40px' }}
          disabled={disable}
          onClick={handleCopy}
          data-testid="copy-btn"
        >
          <>{icon ? icon : t('copyBtn')}</>
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default Copy;
